<template>
  <div class="mt-2 container-fluid">
    <div class="row">
      <div class="col-lg-12 mx-auto">
        <div class="card">
          <div class="card-header">
            <i class="fa fa-credit-card"></i> Facturación   
          </div>
          <div class="card-body">  
            <div class="row">
              <div class="col-lg-3">
                <small>Estado:</small>
                <div class="input-group">
                  <select
                      class="form-select form-select-sm"                        
                      v-model="estado"
                      @change="listar()"
                  >
                    <option v-for="(item,index) in estados" :key="index" :value="item.id">{{item.valor}}</option>
                  </select>
                  <a class="btn btn-secondary btn-sm" @click="verAyuda()"><i class="fa fa-question-circle"></i></a>
                </div>                
              </div>              
              <div class="col-lg-5">   
                <small>Búsqueda:</small>  
                <div class="input-group">
                  <input type="text" class="form-control form-control-sm" placeholder="Buscar por NIT / CI o nombres..." v-model="valor" v-on:keyup.enter="listar()">
                  <a class="btn btn-secondary btn-sm" @click="listar()"><i class="fa fa-search"></i> Buscar</a>
                  <a class="btn btn-secondary btn-sm" @click="valor='';listar()"><i class="fa fa-times"></i></a>
                </div>           
                
              </div>
            </div>
            <div class="row">             
              <div class="col md-12">
                <div class="card border-primary">                    
                  <div class="card-body">  
                    <div class="table-responsive" style="overflow-y:scroll; height:550px;">
                      <table class="table table-sm table-bordered table-condensed">
                        <thead>
                          <tr :class="estado=='PROCESADO' ? 'table-success' : 'table-danger'">
                            <th>NIT</th>
                            <th>Cliente</th>
                            <th>Nro. Factura</th>
                            <th>Detalle</th>  
                            <th>Fecha de emisión</th>
                            <th>Tipo de Depósito</th>                        
                            <th>Importe Neto</th>
                            <th>Acciones</th>
                          </tr>
                        </thead>                          
                        <tbody>
                          <tr v-for="(item, index) in registros" :key="index" >                          
                            <td>
                              {{item.nitci}}                   
                            </td>
                            <td>
                              {{item.razonsocial}}                   
                            </td>
                            <td>
                              {{item.nro_factura}}                   
                            </td>
                            <td>
                              {{item.arancel}}                    
                            </td>
                            <td>
                              {{formatoFecha(item.fecha)}} -
                              <small class="text-info">{{formatoHora(item.fecha)}}</small>
                            </td>
                            <td>
                              {{item.tipo_deposito}}                    
                            </td>
                            <td>
                              {{item.importe}} Bs.-
                            </td>    
                            <td>
                              <a target="_blank" v-if="item.estado_sufe=='PROCESADO'" class="btn btn-sm btn-info ml-4"  :href="urlPDF + item.cuf +'.pdf'"><i class="fa fa-print"></i> PDF</a>
                              <a v-if="item.estado_sufe=='PROCESADO'" class="btn btn-sm btn-danger ml-4" @click="showModalAnular(item)"><i class="fa fa-times"></i> Anular</a>
                              <a v-if="item.estado_sufe=='OBSERVADO'" class="btn btn-sm btn-secondary ml-4" @click="showModal(item)"><i class="fa fa-search"></i> Ver</a>
                            </td>                      
                          </tr>
                        </tbody>
                      </table>                        
                    </div>

                    <div class="overflow-auto">
                      <b-pagination
                        class="mb-1 mt-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-text="<<"
                        prev-text="<"
                        next-text=">"
                        last-text=">>"
                        size="sm"
                        @change="cambio"
                        v-if="rows>10"
                      ></b-pagination>
                      <small class="text-muted">Página: {{ currentPage}} | Registros: del {{ ((currentPage*perPage) - perPage)+1 }} al {{currentPage*perPage}} | Total:  {{rows}}</small>                       
                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>
        </div> 
      </div>
    </div>
    <Loading v-show="cargando" />

    <b-modal
        class="modal fade"
        :title="tituloModal"
        size="lg"
        ref="observado"
        hide-footer
        :no-close-on-backdrop="true"
    >
      <div class="container">
        <div class="card-body">
          <div class="alert alert-danger">
            <div class="row">
              <div class="col-md-2 text-center">
                <i class="fa fa-times fa-5x"></i>
              </div>
              <div class="col-md-10">
                <p><strong>Estado:</strong> {{facturaElegida.estado_sufe}}</p>
                <p><strong>Código Impuestos Nacionales:</strong> {{facturaElegida.codigo_estado_impuestos}}</p>
                <p><strong>Observación Impuestos Nacionales:</strong></p>
                <p>{{facturaElegida.observacion}}</p>
              </div>
            </div>
            
          </div>
          <p><strong>CUF:</strong> {{facturaElegida.cuf}}</p>
          <p><strong>Nro. Factura:</strong> {{facturaElegida.nro_factura}}</p>
          <p><strong>NIT:</strong> {{facturaElegida.nitci}}</p>
          <p><strong>Razón Social:</strong> {{facturaElegida.razonsocial}}</p>
          <p><strong>Importe:</strong> {{facturaElegida.importe}} Bs.-</p>
          <p>
            <strong>Fecha Emisión:</strong> {{formatoFecha(facturaElegida.fecha)}} -
            <small class="text-info">{{formatoHora(facturaElegida.fecha)}}</small>
          </p>
        </div>
        <hr>
        <div class="row">
          <div class="text-end">
            <a class="btn btn-secondary" @click="hideModal()"><i class="fa fa-times"></i> Cerrar</a>
          </div>          
        </div>
      </div>
    </b-modal>

    <b-modal
        class="modal fade"
        :title="tituloModal"
        size="lg"
        ref="anulacionFactura"
        hide-footer
        :no-close-on-backdrop="true"
    >
      <div class="container">
        <div class="card-body">
          <div class="row">         
              <p><strong>CUF:</strong> {{facturaElegida.cuf}}</p>     
              <p>
                <strong>Fecha Emisión:</strong> {{formatoFecha(facturaElegida.fecha)}} -
                <small>{{formatoHora(facturaElegida.fecha)}}</small>
              </p>
              <div class="col-lg-6">
                <p><strong>Nro. Factura:</strong> {{facturaElegida.nro_factura}}</p>
                <p><strong>NIT:</strong> {{facturaElegida.nitci}}</p>
              </div>
              <div class="col-lg-6">
                <p><strong>Razón Social:</strong> {{facturaElegida.razonsocial}}</p>
                <p><strong>Importe:</strong> {{facturaElegida.importe}} Bs.-</p>
              </div>          
          </div>     
          <div class="row">            
            <div class="col-md-4">
              <div class="form-group">
                <small class="col-form-label col-form-label-sm" >Tipo Anulación:</small>
                <select
                    class="form-select form-select-sm"                        
                    v-model.trim="$v.formAnulacion.idTipoAnulacion.$model"                    
                  >
                    <option v-for="(item,index) in motivos" :key="index" :value="item.id">{{item.descripcion}}</option>
                </select>
              </div>  
            </div>
            <div class="col-md-8">
              <div class="form-group">
                <small class="col-form-label col-form-label-sm" >Motivo:</small>
                <input class="form-control form-control-sm" type="text" placeholder="Descripción Breve del motivo..." v-model.trim="$v.formAnulacion.motivo.$model">
              </div>  
            </div>
          </div>
          <div class="alert alert-danger text-center mt-3">
            <h5 >¿Está seguro que desea anular la factura?</h5>
          </div>     
        </div>
        <hr>
        <div class="row">
          <div class="text-end">
            <button class="btn btn-danger" @click="anular()" :disabled="$v.$invalid"><i class="fa fa-ban"></i> Si, Anular</button>
            <a class="btn btn-secondary" @click="hideModalAnular()"><i class="fa fa-times"></i> Cerrar</a>
          </div>          
        </div>
      </div>
    </b-modal>

    <b-modal
        class="modal fade"
        :title="tituloModal"
        size="md"
        ref="info"
        hide-footer
        :no-close-on-backdrop="true"
    >
      <div class="container">
        <div class="card-body">
          <div class="row">  
            <p><strong>FACTURADO: </strong> Impuestos Nacionales emitió la factura correctamente</p>
            <p><strong>OBSERVADO: </strong> Impuestos Nacionales observó la factura (verifique los datos para subsanar)</p>
            <p><strong>ANULADO: </strong> El usuario anuló la factura.</p>
          </div>  
        </div>
        <hr>
        <div class="row">
          <div class="text-end">            
            <a class="btn btn-secondary" @click="hideModalInfo()"><i class="fa fa-times"></i> Cerrar</a>
          </div>          
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { required, minLength , helpers} from "vuelidate/lib/validators";
import Loading from '@/components/Loading';
import moment from 'moment';
const letras = helpers.regex("alpha", /^[a-zA-ZñÑ ]*$/);

export default {
  data() {
    return {
      cargando:false,
      anulando:false,
      registros: [],
      currentPage:1,
      rows:0,
      perPage:10,
      valor:'',
      facturaElegida: {},
      tituloModal:'',
      fechaMax: new moment().format("YYYY-MM-DD"),
      busqueda: {
        fechaInicio:null,
        fechaFin:null
      },
      urlPDF: process.env.VUE_APP_URL_SUFE_PDF,
      estado:'PROCESADO',
      estados:[{id:'PROCESADO','valor':'FACTURADO'}, {id:'ANULADO','valor':'ANULADO'}, {id:'OBSERVADO','valor':'OBSERVADO'}],
      motivos:[{id:1, descripcion:'Factura mal emitida'}, {id:3, descripcion:'Datos de emisión incorrectos'}, {id:4, descripcion:'Factura o nota de credito-Debito devuelta'}],
      formAnulacion:{
        motivo:'',
        idTipoAnulacion: 0
      }
    }
  },
  components: {
    Loading    
  },
  
  methods: {
    async listar() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/facturas/${this.estado}/${this.currentPage}/${this.valor}`);
        this.registros = resultados.data.contenido.registros; 
        this.rows = resultados.data.contenido.total;
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    cambio(value){
      this.currentPage = value;
      this.listar();
    },
    formatoFecha(fecha){
      return moment(fecha).format('DD/MM/YYYY');
    },
    formatoHora(fecha){
      return moment(fecha).format('HH:mm:ss');
    },    
    showModal(item) {
      this.facturaElegida = item;
      this.$refs["observado"].show();
      this.tituloModal='Observaciones de la Factura'; 
    },
    hideModal() {
      this.$refs["observado"].hide(); 
    },  
    showModalAnular(item) {
      this.formAnulacion={
        motivo:'',
        idTipoAnulacion: 0
      };
      this.facturaElegida = item;
      this.$refs["anulacionFactura"].show();
      this.tituloModal='Anular Factura'; 
    },
    hideModalAnular() {
      this.$refs["anulacionFactura"].hide(); 
      this.listar();
    },  
    async anular(){
      this.formAnulacion.id_factura = this.facturaElegida.id_factura;
      this.cargando = true;  
      try {
        let res = await this.axios.patch(`/anularFactura/${this.facturaElegida.cuf}`, this.formAnulacion);  
        if(res.data.codigo ==1){
          this.$alert("Se anuló correctamente","Atención","success"); 
        }else{
          this.$alert(res.data.mensaje,"Atención","error"); 
        } 
        this.hideModalAnular();
        this.cargando = false; 
      } catch (error) {
        this.cargando = false;    
        console.log(error);
      }  
    },
    verAyuda(){
      this.tituloModal='información de Estados';
      this.$refs["info"].show();
    },
    hideModalInfo() {
      this.$refs["info"].hide(); 
    }
  },
  created(){
    this.listar();
  },
  validations: {
    formAnulacion: {
      motivo: { required, letras, minLength: minLength(3) },    
      idTipoAnulacion: {required}
    }
  }

}
</script>
